(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/jackpots.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/jackpots.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  processJackpotData,
  filterByProducts
} = svs.content.shared.jackpotHelpers;
const jupiterGet = url => new Promise((resolve, reject) => {
  jupiter.get(url, response => {
    resolve(response);
  }, err => {
    reject(err);
  });
});
const getJackpots = async products => {
  if (!Array.isArray(products)) {
    throw new Error('product must be an array');
  }
  const response = await jupiterGet('/draw/1/jackpots');
  const jackpots = processJackpotData(response.jackpots);
  return filterByProducts(products, jackpots);
};
setGlobal('svs.content.shared.getJackpots', getJackpots);

 })(window);