(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/api.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/api.js');

'use strict';

function responseFilter(resp, withLegacyFilter) {
  const {
    gameAnalysisFilter,
    gameAnalysisFilterLegacy,
    shouldRunGameAnalysisFilter
  } = svs.content.shared.filters.gameAnalysisFilter;
  if (shouldRunGameAnalysisFilter(resp)) {
    if (withLegacyFilter === undefined || withLegacyFilter) {
      return gameAnalysisFilterLegacy(resp);
    }
    return gameAnalysisFilter(resp);
  }
  return resp;
}

function basicFilter(filter, callback) {
  const apiUtils = svs.content.shared;
  const validationError = apiUtils.validateOptions(filter, ['type']);
  if (validationError) {
    if (callback && typeof callback === 'function') {
      callback(validationError);
      return;
    }
    return $.Deferred().reject(validationError);
  }
  const params = {
    channel: svs.core.data.capabilities.channel,
    contentType: filter.type,
    count: filter.count || 1,
    domainCategories: filter.domainCategories,
    include: filter.include,
    matchAllDomainCategories: filter.matchAllDomainCategories,
    modifiedSince: filter.modifiedSince,
    offset: filter.offset || 0,
    orderBy: filter.orderBy,
    parentId: filter.parentId,
    sortOrder: filter.sortOrder
  };
  apiUtils.appendUserParams(params);
  let cbk;
  if (callback) {
    cbk = (err, resp) => {
      callback(err, responseFilter(resp, filter.withLegacyFilter));
    };
    return apiUtils.callJupiter(new apiUtils.ContentUrlBuilder('basicfilter', filter.routesDomain).addParams(params).build(), cbk);
  }
  return apiUtils.callJupiter(new apiUtils.ContentUrlBuilder('basicfilter', filter.routesDomain).addParams(params).build())
  .then(resp => $.Deferred().resolve(responseFilter(resp, filter.withLegacyFilter)))
  .catch(err => $.Deferred().reject(err));
}

function getGameAnalyses(filter, callback) {
  const domainCategories = [];
  if (filter.id) {
    if (filter.idType === 'match') {
      domainCategories.push("svs-domain-sport-events/".concat(filter.id));
    } else if (filter.idType === 'race') {
      domainCategories.push("svs-domain-sport-events-racing/".concat(filter.id));
    } else {
      if (callback && typeof callback === 'function') {
        callback("unsupported idType: ".concat(filter.idType));
        return;
      }
      return $.Deferred().reject("unsupported idType: \"".concat(filter.idType, "\""));
    }
  }
  if (filter.product) {
    domainCategories.push("svs-domain-products/".concat(filter.product));
  }
  if (filter.raceProduct) {
    domainCategories.push("svs-domain-products-racing/".concat(filter.raceProduct));
  }
  if (filter.participantId) {
    domainCategories.push("svs-domain-sport-participants/".concat(filter.participantId));
  }
  const params = {
    count: filter.count,
    domainCategories,
    include: filter.include,
    matchAllDomainCategories: typeof filter.matchAll !== 'undefined' ? filter.matchAll : true,
    offset: filter.offset,
    orderBy: 'authorProfile.rank',
    sortOrder: 'desc',
    routesDomain: 'partner',
    type: 'cnt:gameAnalysis',
    withLegacyFilter: typeof filter.withLegacyFilter === 'boolean' ? filter.withLegacyFilter : true
  };
  return basicFilter(params, callback);
}

function getDrawAnalyses(filter, callback) {
  const domainCategories = [];
  if (filter.id) {
    const domain = filter.idType === 'race' ? 'svs-domain-sport-events-racing' : 'svs-domain-sport-draws';
    if (Array.isArray(filter.id)) {
      for (let i = 0; i < filter.id.length; ++i) {
        domainCategories.push("".concat(domain, "/").concat(filter.id[i]));
      }
    } else {
      domainCategories.push("".concat(domain, "/").concat(filter.id));
    }
  }
  if (filter.product) {
    domainCategories.push("svs-domain-products/".concat(filter.product));
  }
  if (filter.raceProduct) {
    domainCategories.push("svs-domain-products-racing/".concat(filter.raceProduct));
  }
  const params = {
    count: filter.count,
    domainCategories,
    include: filter.include,
    matchAllDomainCategories: typeof filter.matchAll !== 'undefined' ? filter.matchAll : true,
    offset: filter.offset,
    orderBy: 'authorProfile.rank',
    sortOrder: 'desc',
    routesDomain: 'partner',
    type: 'cnt:drawAnalysis',
    withLegacyFilter: typeof filter.withLegacyFilter === 'boolean' ? filter.withLegacyFilter : true
  };
  return basicFilter(params, callback);
}

function getAuthorProfiles(filter, callback) {
  filter = filter || {};
  const domainCategories = [];
  if (filter.product) {
    domainCategories.push("svs-domain-products/".concat(filter.product));
  }
  const params = {
    count: filter.count || 20,
    domainCategories,
    include: filter.include,
    matchAllDomainCategories: true,
    offset: filter.offset,
    orderBy: 'authorProfile.rank',
    sortOrder: 'desc',
    routesDomain: 'partner',
    type: 'cnt:authorProfile'
  };
  return basicFilter(params, callback);
}

function getContentById(options, callback) {
  const apiUtils = svs.content.shared;
  const validationError = apiUtils.validateOptions(options, ['id']);
  const contentApiVersion = svs.core.config.data.svsconfig.contentApiVersion;
  const routesDomain = svs.core.config.data.svsconfig.routesDomain;
  if (validationError) {
    callback(validationError);
    return;
  }
  apiUtils.callJupiter(new apiUtils.ContentUrlBuilder("/content/".concat(contentApiVersion, "/id/").concat(options.id), routesDomain).build(), callback);
}
svs.content.shared.cms = {
  basicFilter,
  getAuthorProfiles,
  getDrawAnalyses,
  getGameAnalyses,
  getContentById
};

 })(window);