(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/jackpot-helpers.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/jackpot-helpers.js');
"use strict";


const TAGLINE_GUARANTEED = 'alltid minst';
const TAGLINE_JACKPOT = 'ca';
let currencyInMillions;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  currencyInMillions = trinidad.components.require('utils', 'format').api.CurrencyInMillions;
} else {
  currencyInMillions = svs.utils.format.CurrencyInMillions;
}
function formatCurrencyTest(amount) {
  return parseFloat(currencyInMillions(amount).replace(',', '.').replace(' ', ''));
}
function formatAmountString(amount) {
  return "".concat(currencyInMillions(amount), " miljon").concat(formatCurrencyTest(amount) !== 1 ? 'er' : '', " kr");
}
function formatAmountStringShort(amount) {
  return "".concat(currencyInMillions(amount), " milj kr");
}
const processJackpotData = jackpotList => {
  const formattedList = [];
  for (let i = 0; i < jackpotList.length; i++) {
    const productJackpot = jackpotList[i];
    for (let j = 0; j < productJackpot.guaranteedJackpots.length; j++) {
      const o = productJackpot.guaranteedJackpots[j];
      const jackpotAmountString = "".concat(TAGLINE_GUARANTEED, " ").concat(formatAmountString(o.jackpotAmountSek));
      const jackpotAmountStringShort = "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountStringShort(o.jackpotAmountSek));
      formattedList.push({
        productId: productJackpot.productId,
        productName: productJackpot.productName,
        drawNumber: productJackpot.drawNumber,
        description: o.description,
        jackpotAmount: o.jackpotAmountSek,
        jackpotAmountEuro: o.currency && o.currency.match(/euro/i) ? o.jackpotAmount : undefined,
        defaultJackpot: true,
        tagline: TAGLINE_GUARANTEED,
        jackpotAmountString,
        jackpotAmountStringShort,
        guaranteedJackpotAmount: o.jackpotAmountSek,
        guaranteedJackpotAmountString: jackpotAmountString,
        guaranteedJackpotAmountStringShort: jackpotAmountStringShort,
        winset: o.winset,
        windiv: o.windiv
      });
    }
    for (let j = 0; j < productJackpot.jackpots.length; j++) {
      const o = productJackpot.jackpots[j];
      let existsInList = false;
      for (let k = 0; k < formattedList.length; k++) {
        if (formattedList[k].drawNumber === productJackpot.drawNumber && productJackpot.productId === formattedList[k].productId && (Boolean(formattedList[k].description.match(new RegExp("^".concat(o.description), 'i'))) || Boolean(formattedList[k].productName.match(new RegExp("^".concat(o.description), 'i'))) || o.windiv === formattedList[k].windiv && o.winset === formattedList[k].winset)) {
          formattedList[k].jackpotAmount = o.jackpotAmount;
          formattedList[k].windiv = o.windiv;
          formattedList[k].defaultJackpot = false;
          formattedList[k].tagline = TAGLINE_JACKPOT;
          formattedList[k].jackpotAmountString = "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountString(o.jackpotAmount));
          formattedList[k].jackpotAmountStringShort = "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountStringShort(o.jackpotAmount));
          existsInList = true;
          break;
        }
      }
      if (!existsInList) {
        formattedList.push({
          productId: productJackpot.productId,
          productName: productJackpot.productName,
          drawNumber: productJackpot.drawNumber,
          description: o.description,
          jackpotAmount: o.jackpotAmount,
          defaultJackpot: false,
          tagline: TAGLINE_JACKPOT,
          jackpotAmountString: "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountString(o.jackpotAmount)),
          jackpotAmountStringShort: "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountStringShort(o.jackpotAmount)),
          winset: o.winset,
          windiv: o.windiv
        });
      }
    }
  }
  return formattedList;
};
function replaceSweChars(stringToFix) {
  return stringToFix.replace('å', 'a').replace('ä', 'a').replace('ö', 'o');
}
const filterByProducts = (products, jackpots) => {
  if (products.length) {
    let filteredJackpots = [];
    for (let i = 0; i < products.length; i++) {
      const jackpotList = jackpots.filter(j => {
        if (typeof products[i] === 'string' && products[i] === 'dromvinsten') {
          return false;
        }
        if (typeof products[i] === 'string') {
          return replaceSweChars(j.productName.toLowerCase()) === replaceSweChars(products[i]).toLowerCase();
        } else if (typeof products[i] === 'number') {
          return j.productId === products[i];
        }
        return false;
      });
      filteredJackpots = filteredJackpots.concat(jackpotList);
    }
    return filteredJackpots;
  }
  return jackpots;
};
if (svs.isServer) {
  exports.processJackpotData = processJackpotData;
  exports.filterByProducts = filterByProducts;
} else {
  setGlobal('svs.content.shared.jackpotHelpers', {
    processJackpotData,
    filterByProducts
  });
}

 })(window);