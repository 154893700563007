(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/game-analysis-filter-utils.js') >= 0) return;  svs.modules.push('/components/content/shared/game-analysis-filter-utils.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const validTypes = ['cnt:authorProfile', 'cnt:drawAnalysis', 'cnt:gameAnalysis'];
const excludedRootVariables = ['properties', 'parentId', 'parentName', 'rank', 'version'];
const excludedAuthorProfileVariables = [...excludedRootVariables];
const excludedGameAnalysisVariables = [...excludedRootVariables];
const rootRenamePairs = [{
  from: 'domainCategories',
  to: 'gameInfo'
}];
const authorProfileRenamePairs = [{
  from: 'domainCategories',
  to: 'authorInfo'
}];
const gameAnalysisRenamePairs = [{
  from: 'domainCategories',
  to: 'gameAnalysisInfo'
}];

const typeIsValid = result => result ? validTypes.indexOf(result.contentType) > -1 : result;

const deepCopyOf = obj => obj ? JSON.parse(JSON.stringify(obj)) : obj;

const excludeVariableIn = (section, variableName) => {
  if (section) {
    const copyOfSection = deepCopyOf(section);
    delete copyOfSection[variableName];
    return copyOfSection;
  }
};

const renameVariableIn = (json, renamePair) => {
  if (json) {
    const jsonStringSplit = JSON.stringify(json).split(renamePair.from);
    const hasVariable = stringSplit => stringSplit.length !== 1 || stringSplit[0] !== '';
    return hasVariable(jsonStringSplit) ? JSON.parse(jsonStringSplit.join(renamePair.to)) : null;
  }
};

const kebabToCamel = inString => inString.replace(/([-_][a-z])/g, matches => matches.toUpperCase().replace('-', '').replace('_', ''));

const processDomainCategoriesFrom = domainCategories => {
  if (Array.isArray(domainCategories)) {
    const copyOfDomainCategories = deepCopyOf(domainCategories);
    const processedDomainCategories = {};
    copyOfDomainCategories.forEach(domainCategory => {
      const value = domainCategory.split('/')[1];
      const keyName = kebabToCamel(domainCategory.split('svs-domain-')[1].split('/')[0]);
      if (Array.isArray(processedDomainCategories[keyName])) {
        processedDomainCategories[keyName].push(value);
      } else {
        processedDomainCategories[keyName] = [value];
      }
    });
    return processedDomainCategories;
  }
  return domainCategories;
};

const processAuthorProfileFrom = authorProfile => {
  authorProfile.domainCategories = processDomainCategoriesFrom(authorProfile.domainCategories);
  excludedAuthorProfileVariables.forEach(excludedVariable => {
    authorProfile = excludeVariableIn(authorProfile, excludedVariable);
  });
  return authorProfile;
};

const processGameAnalysis = gameAnalysis => {
  const processed = _objectSpread(_objectSpread({}, gameAnalysis), {}, {
    domainCategories: processDomainCategoriesFrom(gameAnalysis.domainCategories),
    eventNumber: gameAnalysis.properties.cnt_eventNumber,
    gamePredictions: JSON.parse(gameAnalysis.properties.cnt_gamePrediction)
  });
  excludedGameAnalysisVariables.forEach(excludedVariable => {
    delete processed[excludedVariable];
  });
  return processed;
};

const processGameAnalysesFrom = gameAnalyses => {
  if (gameAnalyses) {
    const processedGameAnalyses = [];
    gameAnalyses.forEach(gameAnalysis => {
      processedGameAnalyses.push(processGameAnalysis(gameAnalysis));
    });
    return processedGameAnalyses;
  }
};

const processRenamingOn = result => {
  authorProfileRenamePairs.forEach(renamePair => {
    result.authorProfile = renameVariableIn(result.authorProfile, renamePair);
  });
  gameAnalysisRenamePairs.forEach(renamePair => {
    result.gameAnalysis = renameVariableIn(result.gameAnalysis, renamePair);
  });
  rootRenamePairs.forEach(renamePair => {
    result = renameVariableIn(result, renamePair);
  });
  return result;
};
const exportPayload = {
  deepCopyOf,
  typeIsValid,
  processAuthorProfileFrom,
  processGameAnalysis,
  processGameAnalysesFrom,
  processDomainCategoriesFrom,
  excludeVariableIn,
  excludedRootVariables,
  processRenamingOn,
  validTypes
};
if (svs.isServer) {
  module.exports = exportPayload;
} else {
  svs.content.shared.filters.gameAnalysisFilter.gameAnalysisFilterUtils = exportPayload;
}

 })(window);