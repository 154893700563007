(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/game-analysis-filter.js') >= 0) return;  svs.modules.push('/components/content/shared/game-analysis-filter.js');

'use strict';

const gameAnalysisFilterUtils = svs.isServer ? require(require('path').join(__dirname, 'game-analysis-filter-utils.es6')) : svs.content.shared.filters.gameAnalysisFilter.gameAnalysisFilterUtils;

const shouldRunGameAnalysisFilter = resp => {
  const {
    typeIsValid
  } = gameAnalysisFilterUtils;
  let shouldRun = false;
  if (resp.result) {
    resp.result.forEach(result => {
      if (typeIsValid(result)) {
        shouldRun = true;
      }
    });
  }
  return shouldRun;
};

const gameAnalysisFilter = resp => {
  const {
    typeIsValid,
    deepCopyOf,
    processAuthorProfileFrom,
    processGameAnalysis,
    processGameAnalysesFrom,
    processDomainCategoriesFrom,
    excludeVariableIn,
    processRenamingOn,
    excludedRootVariables
  } = gameAnalysisFilterUtils;
  if (resp && resp.result) {
    const rawResults = resp.result;
    const processedResults = [];
    rawResults.forEach(result => {
      if (typeIsValid(result)) {
        let copyOfResult = deepCopyOf(result);
        if (copyOfResult.authorProfile) {
          copyOfResult.authorProfile = processAuthorProfileFrom(deepCopyOf(result.authorProfile));
        }
        const isSingleGameAnalysis = copyOfResult.contentType === 'cnt:gameAnalysis';
        if (isSingleGameAnalysis) {
          copyOfResult = processGameAnalysis(copyOfResult);
        } else if (copyOfResult.gameAnalyses) {
          copyOfResult.gameAnalyses = processGameAnalysesFrom(deepCopyOf(result.gameAnalyses));
        }
        if (copyOfResult.domainCategories) {
          copyOfResult.domainCategories = processDomainCategoriesFrom(deepCopyOf(result.domainCategories));
        }
        if (copyOfResult.properties) {
          const cntGameSystems = copyOfResult.properties.cnt_gameSystems;
          if (Array.isArray(cntGameSystems) && cntGameSystems.length) {
            copyOfResult.gameSystems = copyOfResult.properties.cnt_gameSystems;
            copyOfResult.firstGameSystem = copyOfResult.properties.cnt_gameSystems[0];
          }
        }
        excludedRootVariables.forEach(excludedVariable => {
          copyOfResult = excludeVariableIn(copyOfResult, excludedVariable);
        });
        copyOfResult = processRenamingOn(copyOfResult);
        processedResults.push(copyOfResult);
      }
    });
    resp.result = processedResults;
  }
  return resp;
};

const gameAnalysisFilterLegacy = resp => {
  if (resp && resp.result) {
    const {
      validTypes
    } = gameAnalysisFilterUtils;
    const results = resp.result;
    results.forEach((result, index) => {
      if (validTypes.indexOf(result.contentType) > -1) {
        results[index] = JSON.parse(JSON.stringify(result).split('domainCategories').join('gameinfo'), (key, value) => {
          if (key === 'gameinfo') {
            const gameinfo = {};
            value.forEach(item => {
              let itemValue = item.split('/')[1];
              const itemKeyName = item.split('svs-domain-')[1].split('/')[0];
              if (!gameinfo[itemKeyName]) {
                gameinfo[itemKeyName] = [];
              }
              itemValue = Number.isNaN(Number(itemValue)) ? itemValue : Number(itemValue);
              gameinfo[itemKeyName].push(itemValue);
            });
            return gameinfo;
          } else if (['parentId', 'parentName', 'rank', 'version'].indexOf(key) > -1) {
            return undefined;
          }
          return value;
        });
      }
    });
    resp.result = results;
  }
  return resp;
};
const exportPayload = {
  gameAnalysisFilter,
  gameAnalysisFilterLegacy,
  shouldRunGameAnalysisFilter
};
if (svs.isServer) {
  module.exports = exportPayload;
} else {
  svs.content.shared.filters.gameAnalysisFilter = exportPayload;
}

 })(window);