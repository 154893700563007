(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/image-sizes.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/image-sizes.js');
"use strict";


(function () {
  'use strict';

  const isServer = typeof exports === 'object';

  const MARGIN = 12;
  const LIMIT_XS = 480;
  const LIMIT_CONTENT = 1357;
  const MEDIA_QUERIES = {
    md: "(min-width: ".concat(LIMIT_XS, "px)"),
    lg: "(min-width: ".concat(LIMIT_CONTENT, "px)")
  };

  const ENUM = {
    xs_no_margin: '100vw',
    xs: "calc(100vw - ".concat(MARGIN * 2, "px)"),
    md_6col: "".concat(MEDIA_QUERIES.md, " calc((100vw - ").concat(MARGIN * 3, "px)/2)"),
    md_8col: "".concat(MEDIA_QUERIES.md, " calc((100vw - ").concat(MARGIN * 3, "px)/12*8)"),
    lg: "".concat(MEDIA_QUERIES.lg, " ").concat(LIMIT_CONTENT, "px"),
    lg_6col: "".concat(MEDIA_QUERIES.lg, " ").concat((LIMIT_CONTENT - MARGIN * 3) / 2, "px"),
    lg_8col: "".concat(MEDIA_QUERIES.lg, " ").concat((LIMIT_CONTENT - MARGIN * 3) / 12 * 8, "px"),
    wide: "100vw"
  };

  function getMediaQuery(sizes) {
    return sizes.join(', ');
  }

  function getMediaQueryForColumn(columnIndex, columns) {
    const fullWidthSizes = [ENUM.lg, ENUM.xs];
    const columnWidthSizes = [ENUM.lg_6col, ENUM.md_6col, ENUM.xs];
    const isLastPosAndOdd = columnIndex === columns.length - 1 && (columnIndex + 1) % 2 === 1;
    return getMediaQuery(isLastPosAndOdd ? fullWidthSizes : columnWidthSizes);
  }

  if (isServer) {
    module.exports = {
      ENUM,
      MEDIA_QUERIES,
      getMediaQuery,
      getMediaQueryForColumn
    };
  } else {
    svs.content.shared.imageSizes = {
      ENUM,
      MEDIA_QUERIES,
      getMediaQuery,
      getMediaQueryForColumn
    };
  }
})();

 })(window);