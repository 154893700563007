(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/api-utils.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/api-utils.js');
"use strict";


(function () {
  'use strict';

  class ContentUrlBuilder {
    constructor(path, routesDomain) {
      const contentApiVersion = svs.core.config.data.svsconfig.contentApiVersion || 1;
      this.path = path;
      this.routesDomain = routesDomain;
      if (!this.path) {
        throw new Error('no path-parameter specified to constructor');
      }
      this.path = path.indexOf('/') === 0 ? path : "/".concat(path);
      if (this.path.indexOf('/content') !== 0) {
        this.path = "/content/".concat(contentApiVersion).concat(this.path);
      }
      this.params = '';
      this.addParams({
        routesDomain: routesDomain || svs.core.config.data.svsconfig.routesDomain
      });
    }

    addParams(newParams) {
      const newParamsString = Object.keys(newParams).filter(field => newParams[field] !== null && newParams[field] !== undefined).map(field => [field, newParams[field]].join('=')).join('&');
      if (newParamsString) {
        this.params += (this.params ? '&' : '?') + newParamsString;
      }
      return this;
    }

    build() {
      return this.path + this.params;
    }
  }

  const validateOptions = (options, requiredProperties) => {
    if (!options) {
      return 'options required';
    }
    requiredProperties = requiredProperties || [];
    for (let i = 0; i < requiredProperties.length; i++) {
      if (!options[requiredProperties[i]]) {
        return "".concat(requiredProperties[i], " required");
      }
    }
    return null;
  };

  function appendUserParams(params) {
    params.userSegment = svs.core.userSession.getSegmentId() || 0;
  }

  function callJupiter(path, callback) {
    const useCallback = callback && typeof callback === 'function';
    return svs.core.jupiter.call({
      context: this,
      data: {},
      method: 'GET',
      path
    }, useCallback ? data => {
      callback(null, data);
    } : undefined, useCallback ? err => {
      callback(err);
    } : undefined);
  }
  svs.content.shared.ContentUrlBuilder = ContentUrlBuilder;
  svs.content.shared.appendUserParams = appendUserParams;
  svs.content.shared.validateOptions = validateOptions;
  svs.content.shared.callJupiter = callJupiter;
})();

 })(window);