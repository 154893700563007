(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/shared/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/content/shared/assets/javascripts/prescript.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.content = svs.content || {};
  svs.content.shared = svs.content.shared || {};
  svs.content.shared.filters = svs.content.shared.filters || {};

  const filters = svs.content.shared.filters;

  filters.gameAnalysisFilter = filters.gameAnalysisFilter || {};
  filters.gameAnalysisFilter.gameAnalysisFilterUtils = filters.gameAnalysisFilter.gameAnalysisFilterUtils || {};
})(svs);


 })(window);